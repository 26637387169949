import React, {useEffect, useState} from "react"
import { connect, useSelector } from "react-redux"
import { getContestDetails } from "../../actions"
import Layout from "../../components/layout"
import {Table as BTable} from "react-bootstrap";
import './styles.css'
import Pagination from "@material-ui/lab/Pagination";
import DataTable from "react-data-table-component";

const Contest = () => {
    const [state, setState] = useState({
        hasNextPage: true,
        hasPrevPage: false,
        limit: 10,
        nextPage: 2,
        page: 1,
        pagingCounter: 1,
        prevPage: null,
        totalDocs: 12,
        totalPages: 2,
        docs: [],
        loading: false,
        errorMessage: null,
        isPagination: true
    })
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [users, setUsers] = useState([])
    useEffect(()=>{
        getContestDetails(pageNumber).then(result =>{
            result.result.docs.map((doc, index)=>{
                result.result.docs[index] = {
                    ...doc,
                    index: index+1
                }
            })
            setUsers(result.result.docs)
            setTotalPages(result.result.totalPages)
        })
    }, [pageNumber])

    const language = useSelector(state => state.language)
    const header = ['Platform Login', 'Initial Deposit', 'Created At', 'Profit']
    const columns = [
        {
            name: 'Number',
            selector: 'index',
            center: true,
            // maxWidth: '20',
            // grow: 1,
            // width: '0px',
            allowOverflow: false
        }   ,{
        name: 'Account Number',
        selector: 'platformLogin',
        sortable: true,
        center: true,
        // grow: 3
    }   ,
        {
            name: 'Initial Deposit',
            selector: (row, index) => {
                return `$ 10000`
            },
            sortable: false,
            center: true,
            // grow: 3
        },
        {
            name: 'Profit',
            selector: (row, index) => {
                return `$ ${row.profit}`
            },
            sortFunction: (a,b) => {
                let tmp1 = a.profit;
                let tmp2 = b.profit;
                return tmp1 -  tmp2;
            },
            sortable: true,
            center: true,
            // grow: 3
        }]

        const columnsAr = [{
            name: 'رقم',
            selector: 'index',
            center: true
        },{
            name: 'رقم حساب',
            selector: 'platformLogin',
            sortable: true,
            center: true
        },
            {
                name: 'ألإيداع المبدئى',
                selector: (row, index) => {
                    return `$ 10000`
                },
                sortable: false,
                center: true
            },
            {
                name: 'الربح',
                selector: (row, index) => {
                    return `$ ${row.profit}`
                },
                sortable: true,
                sortFunction: (a,b) => {
                    let tmp1 = a.profit;
                    let tmp2 = b.profit;
                    return tmp1 -  tmp2;
                },
                center: true
            }]
    const body = [['asda1','s1', 'asd1','asd', 'asdcvhf', 'test'],['asda2','s2', 'asd2','asd', 'asdcvhf', 'test'],['asda3','s3', 'asd3','asd', 'asdcvhf', 'test']]
    const paginationHandler = (prams, value) => {
        setPageNumber(value)
    };

    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
            }
        },
        rdt_TableHeader: {
            background: '#1176bc'
        },
        headCells: {
            style: {
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                minHeight: '72px'
            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    return (
        <Layout>
            <section className="job-contents mt-4 p-top-20 p-bottom-65">
                <div className="container">
                    <h2 className="text-center mt-2 mb-0">
                        {language === 'en-gb' ? 'Contest Result': 'نتيجة المسابقات'}
                    </h2>
                    <div className="tables-responsive text-center">
                        <DataTable
                            direction={language==='en-gb'?'ltl':'rtl'}
                            columns={language==='en-gb'?columns:columnsAr}
                            data={users}
                            pagination={true}
                            customStyles={customStyles}
                            filterable
                        />
                        {/*<BTable responsive="lg" className='table-striped'>*/}
                        {/*    <thead className="table-secondary table-secondary--darken custom-header">*/}
                        {/*    <tr className=''>*/}
                        {/*        {header.map((item, index) => (*/}
                        {/*            <th key={index}>{item}</th>*/}
                        {/*        ))}*/}
                        {/*    </tr>*/}
                        {/*    </thead>*/}
                        {/*    <tbody>*/}
                        {/*    {users.map((user, index) => (*/}
                        {/*        <tr key={index} className='border-between'>*/}
                        {/*            <td className='bordered-row' key={index}>{user.entryPoint}</td>*/}
                        {/*            <td className='bordered-row' key={index}>{user.name}</td>*/}
                        {/*            <td className='bordered-row' key={index}>{user.platformLogin}</td>*/}
                        {/*            <td className='bordered-row' key={index}>{user.initialDeposit}</td>*/}
                        {/*            <td className='bordered-row' key={index}>{`${new Date(user.accountCreatedDate).toLocaleString()}`}</td>*/}
                        {/*            <td className='bordered-row' key={index}>{user.profit}</td>*/}
                        {/*        </tr>*/}
                        {/*    ))}*/}
                        {/*    </tbody>*/}
                        {/*</BTable>*/}
                    </div>
                    {/*{state.totalPages > 1 && state.isPagination && !state.loading && !state.errorMessage && (*/}
                    {/*    <div className="d-flex align-items-center justify-content-center flex-wrap my-2">*/}
                    {/*        <Pagination*/}
                    {/*            className="pagination-first"*/}
                    {/*            count={totalPages}*/}
                    {/*            page={pageNumber}*/}
                    {/*            onChange={paginationHandler}*/}
                    {/*            variant="text"*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </div>
            </section>
        </Layout>
    )
}

const mapStateToProps = state => ({ language: state.language })

export default connect(mapStateToProps)(Contest)
